
  function downscale(length, expectedMaxLength, baseFontSize, minFontSize) {
    const scalingFactor = expectedMaxLength / length;

    return Math.max(scalingFactor * (baseFontSize - minFontSize) + minFontSize, minFontSize);
  }

  const labels = document.getElementsByClassName('downscale-font');


  Array.prototype.forEach.call(labels, function(label) {

      var resizedFontSize = downscale(label.textContent.length, 3, 1, .35);
      
      if (label.textContent.length <= 3) {
        resizedFontSize = 1;
      }
      label.style.fontSize = `${resizedFontSize}em`;
  });
