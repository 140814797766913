const navBtn = document.querySelector("header.main-navigation-block button.mobile-menu-button");
const navMenu = document.querySelector("header.main-navigation-block");

if (navBtn) {
    navBtn.addEventListener("click", () => {
        if (navMenu) {
            navMenu.classList.toggle("nav_open");
        }
    });
}

const searchBtnOpen = document.querySelector(".search-wrapper button.search-open");
const searchBtnClose = document.querySelector(".search-wrapper button.search-close");
const searchMenu = document.querySelector(".search-wrapper .search-container");

if (searchBtnOpen) {
    searchBtnOpen.addEventListener("click", (event) => {
        event.preventDefault();
        
        if (searchMenu) {
            searchMenu.classList.toggle("search_open");
        }
        if (searchBtnClose) {
            setTimeout(function(){
                searchBtnClose.classList.toggle("search_open");
            }, 150);
        }
    });
}
if (searchBtnClose && searchMenu) {
    searchBtnClose.addEventListener("click", (event) => {
        event.preventDefault();
       
        searchBtnClose.classList.toggle("search_open");
   
        setTimeout(function(){
            searchMenu.classList.toggle("search_open");
        }, 50);
    
    });
}


