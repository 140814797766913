wp.domReady(() => {
    wp.blocks.unregisterBlockStyle( 'core/button', [ 'fill', 'outline' ] );

    styles = [
        {
            name: 'default',
            label: 'Default',
            isDefault: true
        },
        {
            name: 'secondary',
            label: 'Secondary',
        },
        {
            name: 'primary',
            label: 'Primary',
        },
        {
            name: 'white',
            label: 'White',
        },
        {
            name: 'orange-dark',
            label: 'Dark Orange',
        },
        {
            name: 'orange-light',
            label: 'Light Orange',
        },
        {
            name: 'blue',
            label: 'Blue',
        }
    ];

    wp.blocks.registerBlockStyle( 'core/button', styles );

    variations = [
        {
            name: 'underline',
            title: 'Underline',
            description: 'Underlined button',
            isDefault: true,
            icon: '',
            attributes: {
                align: '',
                className: 'is-button-underline',
                providerNameSlug: 'underline'
            },
            scope: ['transform']
        },
        {
            name: 'pill',
            title: 'Pill',
            description: 'Pill button',
            icon: '',
            attributes: {
                align: '',
                className: 'is-button-pill',
                providerNameSlug: 'pill'
            },
            scope: ['transform']
        }
    ];

    wp.blocks.registerBlockVariation( 'core/button', variations );
} );

if('wp' in window && 'blocks' in wp) {

    const { addFilter } = wp.hooks; 

    /**
     * Add custom attribute for mobile visibility.
     *
     * @param {Object} settings Settings for the block.
     *
     * @return {Object} settings Modified settings.
     */
    function removeAlignAttributes( settings, name ) {
        
        if (name === "core/button") {
            //check if object exists for old Gutenberg version compatibility
            if( typeof settings.attributes !== 'undefined' ){
                if(!settings.hasOwnProperty("supports")){
                    settings.supports = {};
                }

                
                settings.supports.align = false;
               

            }
        }
        return settings;
    }

    addFilter(
        'blocks.registerBlockType',
        'editorskit/custom-disablealignattributes',
        removeAlignAttributes
    );
}