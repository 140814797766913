
if('wp' in window && 'blocks' in wp) {

    const { addFilter } = wp.hooks; 

    /**
     * Add custom attribute for mobile visibility.
     *
     * @param {Object} settings Settings for the block.
     *
     * @return {Object} settings Modified settings.
     */
    function addAttributesHeader( settings, name ) {
        
        if (name === "acf/header") {
            //check if object exists for old Gutenberg version compatibility
            if( typeof settings.attributes !== 'undefined' ){
                if(!settings.hasOwnProperty("supports")){
                    settings.supports = {};
                }

                if(!settings.hasOwnProperty("attributes")){
                    settings.attributes = {};
                }

                settings.attributes = Object.assign( settings.attributes, {
                    gutterHorizontal:{ 
                        type: 'string',
                        default: 'normal',
                    },
                    gutterVertical:{ 
                        type: 'string',
                        default: '0',
                    }
                });
                
                if(!settings.supports.hasOwnProperty("__basejumpGutter")){
                    settings.supports.__basejumpGutter = {};
                }

                settings.supports.__basejumpGutter = {};
                settings.supports.__basejumpGutter = Object.assign(settings.supports.__basejumpGutter, {
                    "horizontal": [
                        {
                            "name": "0",
                            "label": "None"
                        },
                        {
                            "name": "tight",
                            "label": "Tight"
                        },
                        {
                            "name": "snug",
                            "label": "Snug"
                        },
                        {
                            "name": "normal",
                            "label": "Normal"
                        },
                        {
                            "name": "relaxed",
                            "label": "Relaxed"
                        },
                        {
                            "name": "loose",
                            "label": "Loose"
                        }
                    ],
                    "vertical": [
                        {
                            "name": "0",
                            "label": "None"
                        },
                        {
                            "name": "tight",
                            "label": "Tight"
                        },
                        {
                            "name": "snug",
                            "label": "Snug"
                        },
                        {
                            "name": "normal",
                            "label": "Normal"
                        },
                        {
                            "name": "relaxed",
                            "label": "Relaxed"
                        },
                        {
                            "name": "loose",
                            "label": "Loose"
                        }
                    ]
                });

            }
        }
        return settings;
    }

    addFilter(
        'blocks.registerBlockType',
        'editorskit/custom-attributes',
        addAttributesHeader
    );

}