
wp.domReady( function () {
    $styles = [
        {
            name: 'theme-background',
            label: 'White',
        },
        {
            name: 'theme-cream',
            label: 'Cream',
        },
        {
            name: 'theme-primary',
            label: 'Dark Green',
        },
        {
            name: 'theme-secondary',
            label: 'Dark Blue',
        },
    ];

    wp.blocks.registerBlockStyle( 'acf/breadcrumbs', $styles);
} );