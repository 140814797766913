wp.domReady(() => {

    variations = [
        {
            name: 'reset-default',
            title: 'Reset List',
            description: 'Reset list type to default',
            isDefault: true,
            icon: '',
            attributes: {
                className: '',
            },
            scope: ['transform']
        },
        {
            name: 'list-disc',
            title: 'Unordered List Disc',
            description: 'Unordered list disc',
            icon: '',
            attributes: {
                ordered: false,
                className: 'list-disc',
            },
            scope: ['transform']
        },
        {
            name: 'list-circle',
            title: 'Unordered List Circle',
            description: 'Unordered list circle',
            icon: '',
            attributes: {
                ordered: false,
                className: 'list-circle',
            },
            scope: ['transform']
        },
        {
            name: 'list-square',
            title: 'Unordered List Square',
            description: 'Unordered list square',
            icon: '',
            attributes: {
                ordered: false,
                className: 'list-square',
            },
            scope: ['transform']
        },
        {
            name: 'list-decimal',
            title: 'Decimal',
            description: 'Decimal numbers (1, 2, 3, 4)',
            icon: '',
            attributes: {
                ordered: true,
                className: 'list-decimal',
            },
            scope: ['transform']
        },
        {
            name: 'list-upper-alpha',
            title: 'Uppercase Alpha',
            description: 'Alphabetically ordered list, lowercase (A, B, C, D)',
            icon: '',
            attributes: {
                ordered: true,
                className: 'list-upper-alpha',
            },
            scope: ['transform']
        },
        {
            name: 'list-lower-alpha',
            title: 'Lowercase Alpha',
            description: 'Alphabetically ordered list, lowercase (a, b, c, d)',
            icon: '',
            attributes: {
                ordered: true,
                className: 'list-lower-alpha',
            },
            scope: ['transform']
        },
        {
            name: 'list-upper-roman',
            title: 'Uppercase Roman',
            description: 'Roman numbers, uppercase (I, II, III, IV)',
            icon: '',
            attributes: {
                ordered: true,
                className: 'list-upper-roman',
            },
            scope: ['transform']
        },
        {
            name: 'list-lower-roman',
            title: 'Lowercase Roman',
            description: '  Roman numbers, lowercase (i, ii, iii, iv)',
            icon: '',
            attributes: {
                ordered: true,
                className: 'list-lower-roman',
            },
            scope: ['transform']
        }
       
    ];

    wp.blocks.registerBlockVariation( 'core/list', variations );
} );

if('wp' in window && 'blocks' in wp) {

    const { addFilter } = wp.hooks; 

    /**
     * Add custom attribute for mobile visibility.
     *
     * @param {Object} settings Settings for the block.
     *
     * @return {Object} settings Modified settings.
     */
    function addAttributes( settings, name ) {
        
        if (name === "core/list") {
            //check if object exists for old Gutenberg version compatibility
            if( typeof settings.attributes !== 'undefined' ){
                if(!settings.hasOwnProperty("supports")){
                    settings.supports = {};
                }

                if(!settings.hasOwnProperty("attributes")){
                    settings.attributes = {};
                }

                settings.attributes = Object.assign( settings.attributes, {
                    columnCount:{ 
                        type: 'number',
                        default: 1,
                    }
                });
           
                if(!settings.supports.hasOwnProperty("__basejumpColumnCount")){
                    settings.supports.__basejumpColumnCount = true;
                }

            }
        }
        return settings;
    }

    addFilter(
        'blocks.registerBlockType',
        'editorskit/custom-columncountattributes',
        addAttributes
    );
}
