wp.domReady(() => {

    variations = [
        {
            name: 'tight',
            title: 'Tight',
            description: '',
            icon: '',
            attributes: {
                height: '',
                className: 'h-tight'
            },
            scope: ['transform']
        },
        {
            name: 'snug',
            title: 'Snug',
            description: '',
            icon: '',
            attributes: {
                height: '',
                className: 'h-snug'
            },
            scope: ['transform']
        },
        {
            name: 'normal',
            title: 'Normal',
            description: '',
            icon: '',
            attributes: {
                height: '',
                className: 'h-normal'
            },
            scope: ['transform']
        },
        {
            name: 'relaxed',
            title: 'Relaxed',
            description: '',
            icon: '',
            attributes: {
                height: '',
                className: 'h-relaxed'
            },
            scope: ['transform']
        },
        {
            name: 'loose',
            title: 'Loose',
            description: '',
            isDefault: true,
            icon: '',
            attributes: {
                height: '',
                className: 'h-loose'
            },
            scope: ['transform']
        }
    ];

    wp.blocks.registerBlockVariation( 'core/spacer', variations );
} );
