const HistoryButton = (($) => {
    class HistoryButton {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
        this.basePath = new URL('../', window.location.href);
      }
      
      handleClick(event) {
        let previousPath = document.referrer;
        let cleanPath = previousPath.replace(window.location.origin, '').split('?')[0]
        if(cleanPath === this.basePath.pathname){
          event.preventDefault();
          history.back();
        }
      }

      init() {
        this.$node.on('click', this.handleClick.bind(this));
      }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new HistoryButton(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    HistoryButton.init({
       selector: '[data-history-button]',
    });
 
 })(window.jQuery);