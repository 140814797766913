const Toggle = (($) => {
    class Toggle {
        constructor(node) {
            this.node = node;
            this.$node = $(node);

            const config = this.$node.data("toggle");

            const defaultConfig = {
                target: false,
                slideToggle: false,
                targetSlideToggle: false,
                activeClass: "active",
                slideSpeed: 400,
            };

            this.config = { ...defaultConfig, ...config };
        }

        toggleActive(event) {
            this.$node.toggleClass("active");

            if (this.config.slideToggle) {
                this.$node.slideToggle(this.config.slideSpeed);
            }

            $(this.config.target).toggleClass("active");

            if (this.config.targetSlideToggle) {
                $(this.config.target).slideToggle(this.config.slideSpeed);
            }
        }

        init() {
            this.$node.on("click", this.toggleActive.bind(this));
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new Toggle(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    Toggle.init({
        selector: "[data-toggle]",
    });
})(window.jQuery);
