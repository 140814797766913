
if('wp' in window && 'blocks' in wp) {

    const { addFilter } = wp.hooks; 

    /**
     * Add custom attribute for mobile visibility.
     *
     * @param {Object} settings Settings for the block.
     *
     * @return {Object} settings Modified settings.
     */
    function addAttributesCompChart( settings, name ) {
        
        if (name === "acf/comparison-chart") {
            //check if object exists for old Gutenberg version compatibility
            if( typeof settings.attributes !== 'undefined' ){
                if(!settings.hasOwnProperty("supports")){
                    settings.supports = {};
                }


                settings.supports.__experimentalBorder = {
                    "color": true,
                    "radius": true,
                    "style": true,
                    "width": true,
                    "__experimentalDefaultControls": {
                        "color": true,
                        "radius": true,
                        "style": true,
                        "width": true
                    }
                }

            }
        }
        return settings;
    }

    addFilter(
        'blocks.registerBlockType',
        'editorskit/custom-attributes',
        addAttributesCompChart
    );

}