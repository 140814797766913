
const Annountements = (($) => {
    class Annountements {
    
		constructor(node) {
			this.node = node;
			this.$node = $(node);
			this.announcementsNav = 'announcements_nav';
			this.$announcementsNav = $('#'+this.announcementsNav, this.$node);
			this.announcementsDropdown = 'announcements_dropdown';
			this.$announcementsDropdown = $('#'+this.announcementsDropdown, this.$node);
			this.$buttons = $('button[aria-controls]', this.$node);
		}

		checkCookieNoticeCookie() {
			const cookieAnnouncements = this.getCookie('cookieAnnouncements');

			if(cookieAnnouncements === 'true') {
				this.$announcementsNav.addClass('active').attr('aria-expanded', true);
			} else {
				this.$announcementsNav.removeClass('active').attr('aria-expanded', false);
			}
		}

		setCookieNoticeCookie(event) {
			event.preventDefault();
			
			const today = new Date();
			const expiry = new Date(today.getTime() + 1 * 3600 * 1000);
			var secure = '';
			if(this.isSecure()) {
				secure = '; secure';
			}

			var controls = $(event.currentTarget).attr('aria-controls');

			var expended = $("#"+controls).attr('aria-expanded');

			document.cookie='cookieAnnouncements='+expended+'; path=/; expires=' + expiry.toGMTString() + secure;
			this.$node.removeClass('active');
		}

		getCookie(name) {
			var re = new RegExp(name + "=([^;]+)"),
			value = re.exec(document.cookie);
			return (value != null) ? unescape(value[1]) : null;
		}

		isSecure() {    
		    return window.location.protocol == 'https:'; 
		}

		toggleClass(event) {
			var controls = $(event.currentTarget).attr('aria-controls');

         $("#"+controls).toggleClass('active');

         if ( $("#"+controls).hasClass('active') ) {
         	$("#"+controls).attr('aria-expanded', true);
         } else {
         	$("#"+controls).attr('aria-expanded', false);
         }

         if (controls == this.announcementsNav && !$("#"+controls).hasClass('active') ) {
	         $(this.$announcementsDropdown).removeClass('active');
	       	$(this.$announcementsDropdown).attr('aria-expanded', false);
			}

         if (controls == this.announcementsNav) {
         	this.setCookieNoticeCookie(event);
         }
         
		}

		init() {
			this.$buttons.on('click', this.toggleClass.bind(this));
		}
	}

    return {
       init({ selector } = {}) {

          return jQuery(selector).map((index, node) => {
             const module = new Annountements(node);
             module.init();
             module.checkCookieNoticeCookie();
          });
          
        },
    };

  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    Annountements.init({
       selector: '#announcements',
    });
 
 })(window.jQuery);