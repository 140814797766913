const Marketplace = (($) => {
    class Marketplace {
      constructor(node) {
        this.node = node;
        this.$node = $(node);

        
        this.$search = $('#marketplace_search', this.node);
        this.$brand_wrapper = $('.marketpace_brand', this.node);
        this.$cat_wrapper = $('.marketplace_category', this.node);

        this.$brand_items = $('input', this.$brand_wrapper);
        this.$cat_items = $('input', this.$cat_wrapper);
        
        this.$filtered_tags = $('.filter_tags', this.node);
        this.$items = $('.marketplace_item', this.node);
        this.$marketplace_inputs = $('.marketplace_inputs', this.$filtered_tags);

      }

      displayTags( event ) {
        var searchValue = this.$search;
        var filtered_cat_items = $('input:checked', this.$cat_wrapper);
        var filtered_brand_item = $('input:checked', this.$brand_wrapper);

        this.$filtered_tags.html('');

        if (searchValue.val()) {
          this.$filtered_tags.append('<button name="'+$(searchValue).attr('name')+'" value="'+$(searchValue).attr('id')+'" class="bg-tertiary py-2 px-4 items-center inline-flex flex-row gap-relaxed"><div>Keyword: <strong>' + searchValue.val() + '</strong></div><div class="text-xs">X</div></button>');
        }

        if (filtered_cat_items.length) {
          
          filtered_cat_items.each((index, element)=>{
            var $label = $("label[for='"+$(element).attr('id')+"']");
 
            this.$filtered_tags.append('<button name="'+$(element).attr('name')+'" value="'+$(element).attr('id')+'" class="bg-tertiary py-2 px-4 items-center inline-flex flex-row gap-relaxed"><div><strong>' + $label.text() + '</strong></div><div class="text-xs">X</div></button>');
            
          });

        }

        if (filtered_brand_item.length) {
          var $label = $("label[for='"+filtered_brand_item.attr('id')+"']");
 
          this.$filtered_tags.append('<button name="'+$(filtered_brand_item).attr('name')+'" value="'+$(filtered_brand_item).attr('id')+'" class="bg-tertiary py-2 px-4 items-center inline-flex flex-row gap-relaxed"><div>Brand: <strong>' + $label.text() + '</strong></div><div class="text-xs">X</div></button>');
        }

      }

      clearTags( event ) {
        let element = event.target;

        while(element.nodeName !== "BUTTON"){
            element = element.parentNode;
        }

        var inputID = $(element).attr('value');

        if ( $('#'+inputID).is(':checkbox,:radio') ) {
            $('#'+inputID).prop('checked', false);
        } else {
            $('#'+inputID).val('');
        }

        this.onChange();
      }

      onChange( event ) {
        var filteredItems = this.$items;
        this.$items.hide();

        var searchValue = this.$search.val().toLowerCase();
       
        var filtered_cat_items = $('input:checked', this.$cat_wrapper);
        
        if (searchValue || filtered_cat_items.length) {
          this.$brand_items.each(function() { 
            $(this).prop('checked', false); 
          });
        }

        if (searchValue) {
            filteredItems = filteredItems.filter((index, element)=>{

              if ($(element).data('name').toLowerCase().indexOf(searchValue) != -1) {
                return true;
              }
              if ($(element).data('description').toLowerCase().indexOf(searchValue) != -1) {
                return true;
              }
              return false;
            });
        }

        if (filtered_cat_items.length) {

            filteredItems = filteredItems.filter((index, element)=>{
              var matchFound = false;
              
              var categories = $(element).data('cat-slugs');

              if (categories) {
                var categoriesSplit = categories.split(', ');

                filtered_cat_items.each((index, element)=>{
                  
                  if (categoriesSplit.includes($(element).val())) {
                    matchFound = true;
                  }
                });
              }
              return matchFound;
            });
        }
        
        filteredItems.show();

        this.displayTags();
      }
        
      onChangeBrands( event ) {
        var filteredItems = this.$items;
        this.$items.hide();

        var filtered_brand_item = $('input:checked', this.$brand_wrapper);
       
        if (filtered_brand_item.length) {
          
            this.$cat_items.each(function() { 
              $(this).prop('checked', false); 
            });

            this.$search.val('');


            filteredItems = filteredItems.filter((index, element)=>{

              if ($(element).data('slug') == filtered_brand_item.val()) {

                return true;
              }

              return false;
            });
        }

        filteredItems.show();

        this.displayTags();
      }

      init() {
        this.$search.on('input', this.onChange.bind(this) );
        this.$cat_wrapper.on('change', this.onChange.bind(this) );
        this.$brand_wrapper.on('change', 'input', this.onChangeBrands.bind(this) );

        this.$filtered_tags.on('click', this.clearTags.bind(this) );
      }

    }


    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new Marketplace(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    Marketplace.init({
       selector: '.marketplace_filter',
    });

    $(document).on('ajaxComplete', ()=>{
      Marketplace.init({
        selector: '[data-dropdown="false"]',
      });
    })
 })(window.jQuery);