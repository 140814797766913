const Counter = (($) => {
    class Counter {
      constructor(node) {
        this.node = node;
        this.$node = $(node);
      }
      
      animateNumber() {
        let self = this;
        $({ counter: 0 }).animate({
            counter: self.$node.data('counter-number')
        }, {
            duration: 3000,
            easing: 'swing',
            step: function(now) {
                self.$node.text(Math.ceil(now));
            }
        });
      }

      init() {
        this.$node.on('arrive-active', this.animateNumber.bind(this));
      }
    }
  
    return {
       init({ selector } = {}) {
 
          return jQuery(selector).map((index, node) => {
             const module = new Counter(node);
             module.init();
          });
        },
    };
  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    Counter.init({
       selector: '[data-counter-number]',
    });
 
 })(window.jQuery);