if('wp' in window && 'blocks' in wp) {
    var el = wp.element.createElement;
    const styles = [
        {
            name: 'theme-cream-gradient',
            label: 'Cream Gradient'
        },
        {
            name: 'theme-primary-branded',
            label: 'Green Branded'
        },
        {
            name: 'theme-primary',
            label: 'Green'
        },
        {
            name: 'theme-secondary',
            label: 'Blue'
        },
        {
            name: 'theme-cream',
            label: 'Cream'
        },
        {
            name: 'theme-background',
            label: 'White'
        },
    ];

    wp.blocks.registerBlockStyle( 'acf/card-block',  styles );
}
