if('wp' in window && 'blocks' in wp) {

    let styles = [
        {
            name: 'theme',
            label: 'Theme',
            isDefault: true
        },
        {
            name: 'theme-mobile-stack',
            label: 'Theme Mobile Stack',
            isDefault: true
        },
    ];

    wp.blocks.registerBlockStyle( 'core/table', styles);

}


