wp.domReady(() => {

    variations = [
        {
            name: 'theme-primary',
            title: 'Dark Green',
            description: 'Basic Dark Green',
            isDefault: true,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-primary'
            },
            scope: ['transform']
        },
        {
            name: 'slant-theme-primary',
            title: 'Dark Green/White',
            description: 'Slanted Dark Green with white background',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-primary has-slant'
            },
            scope: ['transform']
        },
        {
            name: 'slant-cream-theme-primary',
            title: 'Dark Green/Cream',
            description: 'Slanted Dark Green with cream background',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-primary has-slant has-slant-cream-color'
            },
            scope: ['transform']
        },
        {
            name: 'theme-secondary',
            title: 'Dark Blue',
            description: 'Basic Dark Blue',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-secondary'
            },
            scope: ['transform']
        },
        {
            name: 'slant-theme-secondary',
            title: 'Dark Blue/White',
            description: 'Slanted Dark Blue with white background',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-secondary has-slant'
            },
            scope: ['transform']
        },
        {
            name: 'slant-cream-theme-secondary',
            title: 'Dark Blue/Cream',
            description: 'Slanted Dark Blue with cream background',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-secondary has-slant has-slant-cream-color'
            },
            scope: ['transform']
        },
        {
            name: 'theme-silver',
            title: 'Silver',
            description: 'Basic Dark Blue',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-silver'
            },
            scope: ['transform']
        },
        {
            name: 'slant-theme-silver',
            title: 'Slanted Silver/White',
            description: 'Slant Silver with white background',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-silver has-slant'
            },
            scope: ['transform']
        },
        {
            name: 'slant-cream-theme-silver',
            title: 'Slanted Silver/Cream',
            description: 'Slant Silver with cream background',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-silver has-slant has-slant-cream-color'
            },
            scope: ['transform']
        },
        {
            name: 'theme-cream',
            title: 'Cream',
            description: 'Basic Cream',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-cream'
            },
            scope: ['transform']
        },
        {
            name: 'slant-theme-cream',
            title: 'Cream/White',
            description: 'Slanted Cream with white background',
            isDefault: false,
            icon: '',
            attributes: {
                align: 'full',
                className: 'is-style-theme-cream has-slant'
            },
            scope: ['transform']
        }
    ];

    wp.blocks.registerBlockVariation( 'acf/interior-hero', variations );
} );

