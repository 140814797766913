wp.domReady(() => {
    
    styles = [
        {
            name: 'theme-background',
            label: 'White',
        },
        {
            name: 'theme-cream',
            label: 'Cream',
        },
        {
            name: 'theme-primary',
            label: 'Dark Green',
        },
        {
            name: 'theme-secondary',
            label: 'Dark Blue',
        },
    ];

    wp.blocks.registerBlockStyle( 'acf/gallery', styles );

} );
