
const CookieNotice = (($) => {
    class CookieNotice {
    
		constructor(node) {
			this.node = node;
	        this.$node = $(node);
			this.$button = $('.dismiss-notification', this.$node);
		}

		checkCookieNoticeCookie() {
			const cookieNotice = this.getCookie('cookieNotice');
			
			if(cookieNotice == 'true') {
				this.$node.removeClass('active');
			}
		}

		setCookieNoticeCookie(event) {
			event.preventDefault();
			
			const today = new Date();
			const expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days
			var secure = '';
			if(this.isSecure()) {
				secure = '; secure';
			}
			document.cookie='cookieNotice='+true+'; path=/; expires=' + expiry.toGMTString() + secure;
			this.$node.removeClass('active');
		}

		getCookie(name) {
			var re = new RegExp(name + "=([^;]+)"),
			value = re.exec(document.cookie);
			return (value != null) ? unescape(value[1]) : null;
		}

		isSecure() {    
		    return window.location.protocol == 'https:'; 
		}

		init() {     
			this.checkCookieNoticeCookie();
	      this.$button.on('click', this.setCookieNoticeCookie.bind(this));
		}
	}

    return {
       init({ selector } = {}) {

          return jQuery(selector).map((index, node) => {
             const module = new CookieNotice(node);
             module.init();
          });
          
        },
    };

  })(window.jQuery);

  (($) => {
    let context = $(document);
 
    CookieNotice.init({
       selector: '.alert-message',
    });
 
 })(window.jQuery);