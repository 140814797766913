wp.domReady(() => {

    variations = [
        {
            name: 'theme-cream-branded',
            title: 'Cream Branded',
            description: 'Cream background with brand',
            isDefault: true,
            icon: '',
            attributes: {
                className: 'is-style-theme-cream is-style-branded'
            },
            scope: ['transform']
        },
        {
            name: 'theme-primary-branded',
            title: 'Primary Branded',
            description: 'Primary background with brand',
            icon: '',
            attributes: {
                className: 'is-style-theme-primary is-style-branded'
            },
            scope: ['transform']
        },
        {
            name: 'theme-cream',
            title: 'Cream',
            description: 'Cream background',
            icon: '',
            attributes: {
                className: 'is-style-theme-cream'
            },
            scope: ['transform']
        },
        {
            name: 'theme-primary',
            title: 'Primary',
            description: 'Primary background',
            icon: '',
            attributes: {
                className: 'is-style-theme-primary'
            },
            scope: ['transform']
        }
    ];

    wp.blocks.registerBlockVariation( 'acf/callout-block', variations );
} );

