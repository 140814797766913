if('wp' in window && 'blocks' in wp) {

    const styles = [
        {
            name: 'notch',
            label: 'Notch'
        },
        {
            name: 'notch-alt',
            label: 'Notch Alt'
        }
    ];

    wp.blocks.registerBlockStyle( 'core/image', styles);

    wp.hooks.addFilter(
        'blocks.registerBlockType',
        'basejump/blocks',
        function( settings, name ) {

            if ( name === "core/image" ) {
                if(!settings.hasOwnProperty("supports")){
                    settings.supports = {};
                }

                if(!settings.supports.hasOwnProperty("color")){
                    settings.supports.color = {};
                }

                settings.supports.color = Object.assign(settings.supports.color, {
                    background: true,
                    text: false
                });
            }
            return settings;
        }
    );
}